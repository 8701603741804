let config = {
    s3: {
        REGION: "eu-west-1",
        BUCKET: "forwood-macquarie-staging-forwoodid",
        BATCH_PROCESS_BUCKET_NAME: "forwood-macquarie-staging-forwoodid-batch-user-process"
    },
    appSyncAPI: {
        REGION: "eu-west-1",
        URL: "https://4vpjswbarzgarirvd6qxm433h4.appsync-api.eu-west-1.amazonaws.com/graphql",
    },
    apiGateway: {
        REGION: "eu-west-1",
        URL: "https://tzsbosyjj9.execute-api.eu-west-1.amazonaws.com/env",
        USER_TOKEN_URL: "https://q862t638n1.execute-api.eu-west-1.amazonaws.com/prod"
    },
    configurationService: {
        URL: "https://config.staging.macquarie.forwoodsafety.com",
        WEBSOCKET: "wss://i1hb5vtuq9.execute-api.eu-west-1.amazonaws.com/staging"
    },
    cognito: {
        REGION: "eu-west-1",
        USER_POOL_ID: "eu-west-1_fzVhR9Mmt",
        IDENTITY_POOL_ID: "eu-west-1:848c22f7-8817-4d50-950b-50c19d601d3b",
        USERPOOL_HOSTED_DOMAIN: "forwood-macquarie-id-staging",
        USERPOOL_ADFS_CALLBACK_URL: "https://id.staging.macquarie.forwoodsafety.com",
        USERPOOL_ADFS_LOGOUT_URL: "https://id.staging.macquarie.forwoodsafety.com",
        AUTHENTICATED_ROLE_ARN: "arn:aws:iam::159207976677:role/staging-CA"
    },
    samlProvider: {
      NAME: '',
      SUPPORTED_LIST: 'ForwoodAzure'
    },
    reactApp: {
        VERSION: "1.35.6",
        HOSTNAME: "id.staging.macquarie.forwoodsafety.com",
        ENV_NAME: "staging",
        COOKIE_DOMAIN: ".staging.macquarie.forwoodsafety.com",
        SECURE_PROTOCOL: true,
        FORWOOD_COMPANY_ID: "a542ae2b-6931-4307-8ad6-ae03c182c6ca",
        FORWOOD_HOMEPAGE_MICROFRONTEND_URL: "https://homepage.staging.macquarie.forwoodsafety.com"
    },
    USERPOOL_ADFS_LAUNCH_URL(clientId) {
        return 'https://'+this.cognito.USERPOOL_HOSTED_DOMAIN+'.auth.'+this.cognito.REGION+'.amazoncognito.com/authorize?redirect_uri='+this.cognito.USERPOOL_ADFS_CALLBACK_URL+'&response_type=code&client_id='+clientId;
    },
};

export default config;
